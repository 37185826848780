import React from "react";

function Logs({ driverFreight }) {
    return (
        <>
            <h5 className="mt-3 uppercase">Logs da viagem</h5>
            <div className="overflow-auto">
                <table className="table-auto mt-6 w-full text-left ">
                    <thead className="border-b border-roxo_oficial">
                        <tr>
                            <th className="text-roxo_oficial w-64">Data</th>
                            <th className="text-roxo_oficial">Descrição</th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {driverFreight &&
                                driverFreight?.logs?.length > 0 &&
                                driverFreight.logs.map((entity, key) => {
                                    return (
                                        <tr key={entity.id}>
                                            <td>{entity.createdAtFormatted}</td>
                                            <td>{entity.description}</td>
                                        </tr>
                                    );
                                })}
                            {(!driverFreight || driverFreight?.logs?.length === 0) && (
                                <tr className="text-center">
                                    <td className="text-center" colSpan="9">
                                        Sem logs nesta viagem.
                                    </td>
                                </tr>
                            )}
                        </>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Logs;
